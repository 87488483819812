<template>
  <!-- <a-sky src="/img/glass.jpg"></a-sky> -->
  <div class="left">
    <div class="top-wrap">
      <button class="add-frame" @click="addFrame">+</button>
      <div class="reel" @mousewheel="horizontalScroll($event)">
        <span class="btn-wrap" v-for="(scene, i) in frames" :key="i">
          <button
            v-if="scene"
            @click="frameClicked(i)"
            :class="`frame ${currentFrame === i ? 'active' : ''}`"
          >
            <span class="info num">{{ i }}</span>
            <span class="info title">{{ scene.title }}</span>
            <span v-if="scene.src">
              <video
                v-if="scene.type === 'video/mp4'"
                preload="metadata"
                height="250"
                width="150"
              >
                <source :src="scene.src + '#t=0.1'" type="video/mp4" />
                Your browser does not support HTML video.
              </video>
              <img
                v-else
                :src="scene.src"
                alt="no image"
                height="150"
                width="150"
              />
            </span>
            <div v-else class="empty-image">no image</div>
            <button
              class="remove-btn material-icons"
              @click="frames.splice(i, 1), $store.commit('saveFrames')"
            >
              remove
            </button>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Reel",
  data: function () {
    return {};
  },
  computed: {
    ...mapState(["frames", "currentFrame"]),
  },
  methods: {
    frameClicked: function (index) {
      this.$store.commit("frameClicked", index);
    },
    addFrame: function (index) {
      this.frames.push({
        type: "",
        title: "",
        src: "",
        textList: [
          {
            text: "Text",
            rotation: { x: 0, y: 0, z: 0 },
            textWidth: 6,
            align: "center",
            color: "#ffffff",
            background: false,
            bgWidth: 2,
            bgHeight: 2,
            bgColor: "#ffffff",
            isOpen: false,
          },
        ],
      });
      this.$store.commit("saveFrames");
    },
    horizontalScroll: function (e, delta) {
      document.querySelector(".reel").scrollLeft -= e.deltaY;
      e.preventDefault();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$background: #202124;
$border: #5f6368;
$surface: #1f1f1f;
$primaryTwo: #3700b3;
$primary: #bb86fc;
$primaryHover: #bb86fc8c;

@keyframes fadein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.btn-wrap {
  animation-name: fadein;
  animation-duration: 1s;
}
.add-frame {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 900;
  background: $primary;
  color: white;
  border: 2px solid white;
  position: absolute;
  right: 10px;
  top: 60px;
  transform: translateY(-50%);
  z-index: 5;
  transition: 0.3s;
  box-shadow: 2px 4px white;
  &:hover {
    box-shadow: 0 0 10px white;
    right: 7px;
    top: 69px;
  }
  &:focus {
    outline: none;
  }
}
.reel {
  position: relative;
  display: flex;
  color: white;
  background: $background;
  overflow-x: auto;
  box-shadow: inset 0 0 20px -5px $primaryHover;
  border-radius: 10px;
  .frame {
    background: none;
    border: none;
    cursor: pointer;
  }
  $width: 120px;
  $height: 100px;

  .frame {
    position: relative;
    overflow: hidden;
    min-width: $width;
    min-height: $height;
    max-width: $width;
    max-height: $height;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 10px;
    border-radius: 15px;
    border: 4px solid gray;
    opacity: 1;
    transition: 0.3s;
    .info {
      position: absolute;
      display: block;
      background: white;
      border: 2px solid gray;
      &.num {
        right: -2px;
        top: -2px;
        font-size: 15px;
        padding: 5px 7px 3px 5px;
        border-bottom-left-radius: 10px;
      }
      &.title {
        border-top: 2px solid gray;
        width: 103%;
        bottom: -2px;
        padding: 2px 6px;
        text-align: left;
      }
    }

    &:hover:not(.active) {
      border: 4px solid $primaryHover;
    }
  }

  .active {
    border: 4px solid $primary;
    z-index: 2;
    .num {
      border: 3px solid $primary;
    }
    &:focus {
      outline: none;
    }
  }
  .empty-image {
    width: 150px;
    height: 150px;
    // border: 1px solid white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
