<template>
  <div class="text-tab">
    <div class="tabs">
      <div
        class="tab"
        v-for="(text, j) in frames[currentFrame].textList"
        :key="j"
      >
        <input class="tab-toggle" type="radio" :id="'rd' + j" :name="'rd'" />
        <label class="tab-label" :for="'rd' + j"
          >Text {{ j + 1 }}
          <button
            class="remove-btn material-icons"
            @click="frames[currentFrame].textList.splice(j, 1), saveFrames"
          >
            remove
          </button></label
        >
        <div class="tab-content">
          <div class="inner-wrap">
            <textarea
              class="area form__field"
              name=""
              id=""
              cols="24"
              rows="3"
              v-model="text.text"
              @change="saveFrames"
            ></textarea>
            <div class="settings-wrap">
              <div class="head-wrap">
                <span class="icon material-icons"> format_color_text </span>
                <h4 class="sec-head">Font</h4>
              </div>
              <div class="row">
                <div
                  class="color-input"
                  title="change font color"
                  disabled="true"
                >
                  <div class="input-wrap">
                    <input
                      type="color"
                      id="textColor"
                      name="textColor"
                      v-model="text.color"
                      @change="saveFrames"
                    />
                  </div>
                </div>
                <div class="capsule" title="size">
                  <label for="txtSize">size</label>
                  <input
                    type="range"
                    id="txtSize"
                    name="txtSize"
                    min="1"
                    max="8"
                    step="1"
                    v-model="text.textWidth"
                    @change="saveFrames"
                  />
                </div>
              </div>
            </div>
            <div class="settings-wrap">
              <div class="head-wrap">
                <span class="icon material-icons"> format_color_fill </span>
                <h4 class="sec-head">Background</h4>

                <div class="bg-toggle">
                  <input
                    type="checkbox"
                    :id="'bgToggle' + j"
                    v-model="text.background"
                    @change="saveFrames"
                  />
                  <label
                    :class="`toggle ${
                      text.background ? 'active' : 'disable'
                    } material-icons`"
                    :for="'bgToggle' + j"
                    >{{ text.background ? "toggle_on" : "toggle_off" }}</label
                  >
                </div>
              </div>
              <div
                :class="`row inner-background ${
                  text.background ? 'enabled' : 'disabled'
                }`"
              >
                <div class="color-input" title="change background color">
                  <div class="input-wrap">
                    <input
                      type="color"
                      class="bgColor"
                      id="bgColor"
                      name="bgColor"
                      v-model="text.bgColor"
                      @change="saveFrames"
                    />
                  </div>
                </div>
                <div class="range-wrap">
                  <div class="bg-dimension capsule" title="height">
                    <label title="width" for="bgWidth"
                      >Height</label
                    >
                    <input
                      type="range"
                      id="bgWidth"
                      name="bgWidth"
                      min="1"
                      max="3"
                      step="0.2"
                      v-model="text.bgWidth"
                      @change="saveFrames"
                    />
                  </div>
                  <div class="bg-dimension capsule" title="width">
                    <label title="height" for="bgHeight"
                      >Width</label
                    >
                    <input
                      type="range"
                      id="bgHeight"
                      name="bgHeight"
                      min="1"
                      max="3"
                      step="0.2"
                      v-model="text.bgHeight"
                      @change="saveFrames"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="settings-wrap">
              <div class="head-wrap">
                <span class="material-icons grad-icon"> control_camera </span>
                <h4 class="sec-head">Placement</h4>
              </div>

              <div class="row">
                <div class="range-wrap">
                  <div class="bg-dimension capsule" title="width">
                    <label for="txtWidth"
                      ><span class="material-icons width"> height </span></label
                    >
                    <input
                      type="range"
                      id="txtWidth"
                      name="txtWidth"
                      min="0"
                      max="360"
                      step="0.2"
                      v-model="text.rotation.y"
                      @change="saveFrames"
                    />
                  </div>
                  <div class="bg-dimension capsule" title="height">
                    <label title="height" for="txtHeight"
                      ><span class="material-icons height">
                        height
                      </span></label
                    >
                    <input
                      type="range"
                      id="txtHight"
                      name="txtHight"
                      min="-90"
                      max="90"
                      step="0.2"
                      v-model="text.rotation.x"
                      @change="saveFrames"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="tab">
        <input class="tab-toggle" type="radio" id="rd3" name="rd" />
        <label for="rd3" class="tab-close">Close others &times;</label>
      </div> -->
    </div>
    <button @click="addText" class="add-text">+ TEXT</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TextTab",
  components: {},
  data: function () {
    return {};
  },
  computed: {
    ...mapState(["frames", "currentFrame", "isWireframe"]),
  },
  mounted() {
    if (localStorage.getItem("frames")) {
      try {
        this.frames = JSON.parse(localStorage.getItem("frames"));
        // let frames = JSON.parse(localStorage.getItem("frames"));
        // this.$store.commit("updateFrames", frames);
      } catch (e) {
        // localStorage.removeItem("frames");
        console.log(e);
      }
    }
    console.log(this.frames);
  },
  watch: {},
  methods: {
    addText: function () {
      this.$store.commit("addText");
      this.saveFrames();
    },
    toggleBackground: function (j) {
      this.$store.commit("toggleBackground", j);
    },
    saveFrames: function (j) {
      this.$store.commit("saveFrames");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$background: #1d2939;
$border: #353c4f;
$surface: #171f2c;
$primaryTwo: #3700b3;
$primary: #bb86fc;
$primaryHover: #bb86fc8c;
$error: #cf6679;

$midnight: #2c3e50;
$clouds: #ecf0f1;

.row {
  display: flex;
  transition: max-height 1s;
  background: #171f2c;
  padding: 7px;
  border: 3px solid $border;
  border-radius: 7px;
  &.disabled {
    max-height: 0;
    transform: scaleY(0);
  }
  &.enabled {
    max-height: 100px;
    transform: scaleY(1);
  }
}

// height: 00px;

.up {
  transform: rotate(180deg);
}
label {
  position: relative;
  &:hover {
    .remove-btn {
      display: block;
      position: absolute;
      top: 0;
    }
  }
}
.add-text {
  background: $primary;
  color: white;
  box-shadow: 2px 2px white;
  border-radius: 20px;
  padding: 5px 10px;
  font-weight: 700;
  border: unset;
}
.area {
  border: 3px solid $border;
  border-radius: 10px;
  border-width: 5px;
  padding: 6px;
  &:hover,
  &:focus {
    border-width: 5px;
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
    outline: none;
  }
}
.capsule {
  display: flex;
  align-items: center;
  border-radius: 5px;
  label {
    cursor: pointer;
    padding: 0px 10px;
    border-right: 1px dashed white;
    margin-right: 7px;
  }
  input {

    height: 36px;
  }
}
.settings-wrap {
  margin-bottom: 10px;
  .bg-toggle {
    margin-right: 10px;
    input {
      display: none;
    }
    label.toggle {
      cursor: pointer;
      font-size: 30px;
      &.active {
        color: #38ef7d;
      }
      &.disabled {
        color: $error;
      }
    }
  }
}
.head-wrap {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.bg-dimension {
  display: flex;
  align-items: center;
  .width {
    transform: rotate(90deg);
  }
}
.trans-wrap {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 10px;
  }
}

.text-alignment {
  display: flex;
  align-items: center;
  color: white;
  label {
    cursor: pointer;
  }
}

.color-input {
  display: flex;
  align-items: center;
  padding: 3px;

  input[type="color"] {
    cursor: pointer;
    display: block;
    -webkit-appearance: none;
    border: none;
    border-radius: 5px;
    width: 36px;
    height: 36px;
  }
}

.dimensions-wrap {
  display: flex;
}
.transform-input {
  width: 50px;
  position: relative;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  // margin: 0;
  cursor: col-resize;
  opacity: 0;
  height: 50px;
  transform: rotate(90deg) translateY(13px);
}

.tab-toggle {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tabs {
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  height: 80vh;
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: $midnight;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: darken($midnight, 10%);
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: white;
    background: $background;
    transition: all 0.35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $midnight;
    cursor: pointer;
    &:hover {
      background: darken($midnight, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    background: darken($midnight, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}
</style>
