import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isWireframe: false,
    currentFrame: 0,
    frames: [
      {
        type: "",
        title: "Glass",
        src: "/img/glass.jpg",
        textList: [
          {
            text: "Text",
            rotation: { x: 0, y: 0, z: 0 },
            textWidth: 6,
            align: "center",
            color: "#000000",
            background: false,
            bgWidth: 2,
            bgHeight: 2,
            bgColor: "#ffffff",
            isOpen: false,
          },
        ],
      },
    ],
  },
  mutations: {
    frameClicked(state, payload) {
      state.currentFrame = payload;
    },
    toggleBackground(state, payload) {
      let background = state.frames[state.currentFrame].textList[payload].background;
      background = !background;
    },
    toggleWireframe(state) {
      state.isWireframe = !state.isWireframe;
    },

    updateFrames(state, frames) {
      state.frames = frames;
    },
    addText(state, frames) {
      state.frames[state.currentFrame].textList.push({
        text: "Text",
        rotation: { x: 0, y: 0, z: 0 },
        textWidth: 6,
        align: "center",
        color: "#000000",
        background: false,
        bgWidth: 2,
        bgHeight: 2,
        bgColor: "#ffffff",
        isOpen: false,
      });
    },
    saveFrames(state, frames) {
      localStorage.setItem("frames", JSON.stringify(state.frames));
    },
  },
  actions: {},
  modules: {},
});
