<template>
  <div class="transporter-tab">
    <div class="accordion">
      <div
        class="cord"
        v-for="(text, j) in frames[currentFrame].textList"
        :key="j"
      >
        <input class="tab-toggle" type="radio" :id="'rd' + j" :name="'rd'" />
        <label class="tab-label" :for="'rd' + j"
          >Transport {{ j + 1 }}
          <button
            class="remove-btn material-icons"
            @click="frames[currentFrame].textList.splice(j, 1), saveFrames"
          >
            remove
          </button></label
        >
        <div class="tab-content">

        </div>
      </div>

      <!-- <div class="tab">
        <input class="tab-toggle" type="radio" id="rd3" name="rd" />
        <label for="rd3" class="tab-close">Close others &times;</label>
      </div> -->
    </div>
    <button @click="addText" class="add-text">+ TEXT</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TransporterTab",
  components: {},
  data: function () {
    return {
      fileDragOver: false,
    };
  },
  computed: {
    ...mapState(["frames", "currentFrame"]),
  },

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$background: #202124;
$border: #5f6368;
$surface: #1f1f1f;
$primaryTwo: #3700b3;
$primary: #bb86fc;
$primaryHover: #bb86fc8c;

// .frame-title-wrap {
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   label {
//     font-size: 20px;
//   }
//   input {
//     background: unset;
//     color: white;
//     border: 2px solid $border;
//     border-bottom: 2px solid white;
//     border-radius: 5px 5px 0 0;
//     font-size: 20px;
//     width: unset;
//     max-width: 175px;
//     transition: 0.5s;
//     transition-timing-function: ease-in-out;
//     &:hover {
//       outline: none;
//       border-bottom: 2px solid $primary;
//     }
//     &:focus {
//       outline: none;
//       border-bottom: 2px solid $primary;
//     }
//   }
//   margin-bottom: 20px;
// }
.form__group {
  margin-bottom: 10px;
}
.image-editor {
  color: white;
  .sample {
    width: 100%;
    max-width: 400px;
    height: 100%;
    color: white;
  }
  .file-label {
    display: block;
    width: 100%;
    height: 300px;
    border: solid 1px white;
    position: relative;
    &.active {
      border: dotted 3px yellow;
    }
    input[type="file"] {
      width: 100%;
      height: 100%;
      border: 1px solid red;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
