<template>
  <a-scene class="bottom-wrap" vr-mode-ui="enabled: false" embedded>
    <a-entity camera="fov: 70" look-controls position="0 1.6 0"></a-entity>
    <a-entity
      v-for="(text, index) in frames[currentFrame].textList"
      :key="index"
    >
      <a-sphere
        wireframe-linewidth="20"
        :wireframe="isWireframe"
        position="0 0 0"
        :rotation="`${text.rotation.x} ${text.rotation.y} ${text.rotation.z}`"
        radius="3"
      >
        <!-- Sphere is places with a radius of 0 when background is false -->
        <a-text
          position="0 1.6 -3"
          :align="text.align"
          :color="text.color"
          :value="text.text"
          :width="text.textWidth"
          :geometry="`primitive: ${text.background ? 'plane' : 'sphere'}; radius: 0; width: ${text.bgWidth}; height: ${text.bgHeight} `"
          :material="`color: ${text.bgColor}`"
        ></a-text>
      </a-sphere>
    </a-entity>
    <a-sky :src="frames[currentFrame].src"></a-sky>
  </a-scene>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Scene",
  computed: {
    ...mapState(["frames", "currentFrame", "isWireframe"]),
  },
  data: function () {
    return {
      // isWireframe: false,
    };
  },
  mounted() {
    AFRAME.registerComponent("arrow", {
      schema: {
        direction: {
          type: "vec3",
          default: {
            x: 1,
            y: 0,
            z: 0,
          },
        },
        length: {
          type: "number",
        },
        color: {
          type: "color",
          default: "#ff0",
        },
        headLength: {
          type: "number",
        },
        headWidth: {
          type: "number",
        },
      },
      init: function () {
        var data = this.data;
        var direction = new THREE.Vector3(
          data.direction.x,
          data.direction.y,
          data.direction.z
        );
        var length = data.length || direction.length();
        var headLength = data.headLength || length * 0.2;
        var headWidth = data.headWidth || headLength * 0.2;
        var color = new THREE.Color(data.color);
        this.arrow = new THREE.ArrowHelper(
          direction.normalize(),
          new THREE.Vector3(),
          length,
          color,
          headLength,
          headWidth
        );
        this.el.setObject3D("arrow", this.arrow);
      },
      update: function (oldData) {
        var data = this.data;
        var diff = AFRAME.utils.diff(data, oldData);
        if ("color" in diff) {
          this.arrow.setColor(new THREE.Color(data.color));
        }
        var length;
        if ("direction" in diff) {
          var direction = new THREE.Vector3(
            data.direction.x,
            data.direction.y,
            data.direction.z
          );
          length = direction.length();
          this.arrow.setDirection(direction.normalize());
        }
        if (
          ("direction" in diff && typeof data.length === "undefined") ||
          "length" in diff ||
          "headLength" in diff ||
          "headWidth" in diff
        ) {
          length = data.length || length;
          var headLength = data.headLength || length * 0.2;
          var headWidth = data.headWidth || headLength * 0.2;
          this.arrow.setLength(length, headLength, headWidth);
        }
      },
    });
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
