<template>
  <div class="scenery-tab">
    <section class="image-wrap">
      <div class="form__group field">
        <input
          class="form__field"
          placeholder="Title"
          type="text"
          name="frame-title"
          id="frame-title"
          maxlength="15"
          v-model="frames[currentFrame].title"
          @input="$store.commit('saveFrames')"
        />
        <label for="frame-title" class="form__label">Scene title</label>
      </div>
      <img
        :src="frames[currentFrame].src"
        class="sample"
        alt=""
        v-if="frames[currentFrame].src !== ''"
      />
      <div class="img-upload" v-else>
        <label
          :class="`file-label ${fileDragOver ? 'active' : ''}`"
          for="img-input"
          @dragleave="fileDragOver = false"
          @dragover="fileDragOver = true"
        >
          <!-- @dragstart="fileDragOver = true"
            @dragend="fileDragOver = false" -->
          Upload or Drag & Drop file
          <input
            type="file"
            :class="'img-input'"
            id="img-input"
            name="img-input"
            accept="image/png, image/jpeg, image/webp"
            @change="upload($event)"
        /></label>
      </div>
    </section>
    <section class="audio-wrap"></section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SceneryTab",
  components: {},
  data: function () {
    return {
      fileDragOver: false,
    };
  },
  computed: {
    ...mapState(["frames", "currentFrame"]),
  },

  methods: {
    upload: function (e) {
      let input = document.querySelector(".img-input");
      var frames = this.frames;
      var reader = new FileReader();

      // console.log(e.target.files[0].type);

      this.frames[this.currentFrame].type = e.target.files[0].type;
      reader.addEventListener("load", () => {
        this.frames[this.currentFrame].src = reader.result;
        this.$store.commit("saveFrames");
      });
      reader.readAsDataURL(e.target.files[0]);
      console.log(this.frames[this.currentFrame].src);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$background: #202124;
$border: #5f6368;
$surface: #1f1f1f;
$primaryTwo: #3700b3;
$primary: #bb86fc;
$primaryHover: #bb86fc8c;

.form__group {
  margin-bottom: 10px;
}
section:not(:last-of-type) {
  border-bottom: 2px dashed $border;
}
.scenery-editor {
  color: white;
  .sample {
    width: 100%;
    max-width: 400px;
    height: 100%;
    color: white;
  }
  .file-label {
    display: block;
    width: 100%;
    height: 300px;
    border: solid 1px white;
    position: relative;
    &.active {
      border: dotted 3px yellow;
    }
    input[type="file"] {
      width: 100%;
      height: 100%;
      border: 1px solid red;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

</style>
