<template>
  <div id="app">
    <!-- <div id="nav" style="position: absolute; z-index: 5; background: white">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>

<script>
import Main from "./components/Main.vue";

export default {
  name: "App",
  components: {
    Main,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
$background: #1d2939;
$border: #353c4f;
$surface: #171f2c;
$primaryTwo: #3700b3;
$primary: #bb86fc;
$primaryHover: #bb86fc8c;
$error: #cf6679;



* {
  box-sizing: border-box;
}
body,
html,
#app {
  color: white;
  padding: 0;
  margin: 0;
}
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // margin-top: 60px;
  // width: 100%;
  // height: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -moz-appearance: none;

  width: 100px !important;
  background: red;
  margin: 0;
}
.remove-btn {
  display: none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: $error;
  color: white;
  border-radius: 50%;
  border: 2px solid white;
  font-weight: 900;
  font-size: 15px;
  width: 22px;
  height: 22px;
  padding: 5px;
  transition: 0.2s;
  &:hover {
    font-size: 22px;
    background: white;
    color: $error;
  }
}
.frame:hover {
  .remove-btn {
    display: flex !important;
  }
}


</style>
