<template>
  <div class="editor-comp">
    <div class="editor" v-if="!isMobile">
      <div :class="`tabs`">
        <button
          v-for="(btn, i) in editorBtns"
          :key="i"
          :class="`tab ${activeTab === btn.type ? 'active' : 'inactive'}`"
          @click="activeTab = btn.type"
        >
          <span class="material-icons icon"> {{ btn.iconName }} </span>
          {{ btn.type }}
        </button>
      </div>
      <div
        :class="`tab-content scenery-editor `"
        v-if="activeTab === 'scenery'"
      >
        <SceneryTab />
      </div>
      <div class="tab-content text-editor" v-if="activeTab === 'text'">
        <TextTab />
      </div>
      <div class="tab-content text-editor" v-if="activeTab === 'transporters'">
        <TransporterTab />
      </div>
      <!-- <div class="logo">
        <div class="orb"></div>
        <h1 class="text"><span></span> FORGE <span></span></h1>
      </div> -->
    </div>
  </div>
</template>

<script>
import SceneryTab from "@/components/editor/SceneryTab.vue";
import TextTab from "@/components/editor/TextTab.vue";
import TransporterTab from "@/components/editor/TransporterTab.vue";

import { mapState } from "vuex";
export default {
  name: "Editor",
  components: { SceneryTab, TextTab, TransporterTab },

  data: function () {
    return {
      activeTab: "scenery",
      isMobile: false,
      editorBtns: [
        { type: "scenery", iconName: "vrpano" },
        { type: "text", iconName: "text_fields" },
        // { type: "video", iconName: "movie" },
        // { type: "audio", iconName: "audiotrack" },
        { type: "transporters", iconName: "adjust" },
      ],
    };
  },
  computed: {
    ...mapState(["frames", "currentFrame"]),
  },
  mounted: function () {
    // this.phoneSetup();
    if (AFRAME.utils.device.isMobile()) {
      this.isMobile = true;
    }
  },
  methods: {
    selectTab: function (tabNum, type) {
      let activeTab = this.activeTab;
      let prevTab = querySelector(`.${activeTab}-tab`);
      let tab = querySelector(`.${type}-tab`);
      this.activeTab = type;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$background: #202124;
$border: #5f6368;
$surface: #1d2939;
$primaryTwo: #3700b3;
$primary: #bb86fc;
$primaryHover: #bb86fc8c;
$error: #cf6679;
.editor-comp {
  height: 100%;
  // padding: 20px;
  // display: flex;
  // flex-direction: column;
}
.editor {
  padding: 10px;
  z-index: 1;
  transition: 0.5s;
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tabs {
    .tab {
      background: $background;
      color: white;
      border: 3px solid $border;
      border-radius: 50%;
      transition: 0.3s;
      padding: 10px;
      border-radius: 10px 10px 0 0;
      text-transform: capitalize;
      &.active {
        background: $surface;
        border: white 2px solid;
        border-bottom: $surface;
        // text-shadow: 2px 3px 3px $primary;
        position: relative;
        top: 5px;
      }
      &.inactive {
        cursor: pointer;
        padding: 6px;
        margin: 4px;
        &:hover {
          padding: 10px;
          margin: 0;
          margin-bottom: -2px;
          border: $primary 1px solid;
          // border-bottom: unset;
          position: relative;
          top: 3px;
          color: $primary;
          text-shadow: 1px 1px white;
          // font-size: 20px;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  .tab-content {
    // display: block;
    width: 100%;
    height: 100%;
    background: $surface;
    padding: 10px;
    border: #5f6368 2px solid;
  }
  .scenery-editor {
    color: white;
    .sample {
      width: 100%;
      max-width: 400px;
      height: 100%;
      color: white;
    }
    // .img-upload {
    //   min-height: 100px;
    // }

    .file-label {
      display: block;
      width: 100%;
      height: 300px;
      border: solid 1px white;
      position: relative;
      &.active {
        border: dotted 3px yellow;
      }
      input[type="file"] {
        width: 100%;
        height: 100%;
        border: 1px solid red;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .inputs-wrap {
    .transform-input {
      width: 40px;
    }
  }
}
.logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
  display: flex;
  height: 30px;
  .orb {
    position: relative;
    width: 45px;
    height: 45px;
    margin-right: 10px;
    border-radius: 50%;
    background-image: linear-gradient(315deg, #ff0000 0%, #ffed00 74%);
    box-shadow: 0 0 10px #d43322;
    transition: 3s;
    transition-timing-function: ease-in-out;
    // https://9elements.github.io/fancy-border-radius/
    // https://codepen.io/yamanda/pen/RpNMaY
  }
  @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&display=swap');
  h1.text {
    font-family: 'Cinzel', serif;
    font-size: 30px;
    background: radial-gradient(#eff1ff, #000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 3s;
  }
  &:hover {
    .orb {
      border-radius: 42% 58% 27% 73% / 64% 47% 53% 36%;
      transform: rotate(30deg);
      box-shadow: 0 0 30px #d43322;
      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 30px;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    h1.text {
      background-position: 20px 0;
      text-shadow: 1px 1px 2px white;
    }
  }
}



</style>
