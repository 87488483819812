<template>
  <div class="main">
    <!-- <a-sky src="/img/glass.jpg"></a-sky> -->
    <div class="left">
      <!-- <div class="top-wrap">
      </div> -->
      <div class="reel-wrap">
        <Reel />
      </div>
      <Scene />
    </div>
    <div class="editor-wrap">
      <Editor />
    </div>
  </div>
</template>

<script>
import Reel from "@/components/editor/Reel.vue";
import Scene from "@/components/Scene.vue";
import Editor from "@/components/Editor.vue";
import { mapState } from "vuex";

export default {
  name: "Main",
  components: {
    Reel,
    Scene,
    Editor,
  },
  data: function () {
    return {
      localFrames: [],
      initFrames: [
        {
          type: "",
          title: "Glass",
          src: "/img/glass.jpg",
          textList: [
            {
              text: "Text",
              rotation: { x: 0, y: 0, z: 0 },
              align: "center",
              color: "#fffff",
              background: false,
              bgWidth: 2,
              bgHeight: 2,
              bgColor: "#fffff",
              isOpen: false,
            },
          ],
        },
      ],
    };
  },

  mounted() {
    if (localStorage.getItem("frames")) {
      try {
        let frames = JSON.parse(localStorage.getItem("frames"));
        this.$store.commit("updateFrames", frames);
      } catch (e) {
        console.log(e);
      }
    } else {
      localStorage.setItem("frames", JSON.stringify(this.frames));
      this.frames = JSON.parse(localStorage.getItem("frames"));
    }
  },
  computed: {
    ...mapState(["frames"]),
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$background: #202124;
$border: #5f6368;
$surface: #1f1f1f;
$primaryTwo: #3700b3;
$primary: #bb86fc;
$primaryHover: #bb86fc8c;


.main {
  width: 100%;
  height: 100vh;
  display: flex;
  background: $background;
  .left {
    width: 100%;
  }
}

a-scene {
  // height: calc(100% - 120px);
  z-index: 6;
}
.area-wrap {
  border: 1px solid green;
  // height: 00px;
  .accord {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    // &.closed {
    //   transform: scaleY(1);
    // }
    &.open {
    }
  }
}

.top-wrap {
  position: relative;
  height: 120px;
  padding-right: 60px;
}
.reel-wrap {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 45%;
  z-index: 7;
}
</style>
